<template>
  <div>
    <default-layout-header />
    <default-layout-side-bar />

    <v-content>
      <v-container fluid>
        <v-row class="fill-height px-16">
          <v-col>
            <transition name="fade">
              <router-view />
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
  import DefaultLayoutHeader from '@/components/defaultLayout/DefaultLayoutHeader';
  import DefaultLayoutSideBar from '@/components/defaultLayout/DefaultLayoutSideBar';
  export default {
    name: 'DefaultLayout',
    components: {
      DefaultLayoutHeader,
      DefaultLayoutSideBar,
    },
  };
</script>

<style scoped>

</style>
